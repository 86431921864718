import React, {useState} from 'react'
import {Link, graphql, useStaticQuery} from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'

const MegaMenuStyles = styled.div`
  .mega-menu {
    text-transform: capitalize;
    .inner-2-col {
      max-width: ${props => props.theme.maxWidth};
      margin: 0 auto;
      padding: 40px 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 40px;
    }
    .img {
      text-align: left;
    }
    a {
      text-transform: capitalize;
      display: block;
      border: none;
      padding: 15px 20px;
      text-align: right;
      transition: all 300ms ease-in-out;
      opacity: 1;
      &:hover {
        background: none;
        border: none;
      }
      &[aria-current='page'] {
        border: none;
      }
    }
    .about .about:after,
    .story .story:after,
    .reviews .reviews:after,
    .team .team:after {
      content: '';
      width: 0;
      height: 0;
      border-left: 10px solid ${props => props.theme.red};
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      position: absolute;
      top: calc(50% - 10px);
      right: -12px;
    }
    .gatsby-image-wrapper {
      img {
        object-fit: contain !important;
        object-position: center bottom !important;
        max-width: 100% !important;
      }
    }
    @media (max-width: 900px) {
      .inner-2-col {
        display: block;
        padding: 0;
        .img {
          display: none;
        }
        a {
          text-align: left;
          opacity: 1;
          line-height: 19px;
          display: block;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          text-transform: capitalize;
          font-size: 16px;
        }
      }
    }
  }
`

const MegaMenuAccessories = ({mobileMenuVisible, mobileMenuCloseHandler}) => {
  const data = useStaticQuery(graphql`
    {
      about: file(relativePath: {eq: "nav-image-about.jpg"}) {
        childImageSharp {
          fixed(width: 350, quality: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      story: file(relativePath: {eq: "nav-image-story.jpg"}) {
        childImageSharp {
          fixed(width: 350, quality: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      team: file(relativePath: {eq: "nav-image-team.jpg"}) {
        childImageSharp {
          fixed(width: 350, quality: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      reviews: file(relativePath: {eq: "nav-image-reviews.jpg"}) {
        childImageSharp {
          fixed(width: 350, quality: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      blog: file(relativePath: {eq: "nav-image-blog.jpg"}) {
        childImageSharp {
          fixed(width: 350, quality: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  const [img, setImg] = useState(data.about)
  const [title, setTitle] = useState('About')
  const [link, setLink] = useState('/about/')

  const handleChange = (imgData, title, link) => {
    setImg(imgData)
    setTitle(title)
    setLink(link)
  }

  return (
    <MegaMenuStyles>
      <span className="mega-menu">
        <div className="inner-2-col">
          <div className={`col-left ${img}`}>
            <Link
              to="/about/"
              onMouseOver={() => handleChange(data.about, 'About', '/about')}
              onFocus={() => handleChange(data.about, 'About', '/about')}
              onClick={mobileMenuCloseHandler}
              className="about"
            >
              About Kooi
            </Link>
            <Link
              to="/about/our-story/"
              onMouseOver={() =>
                handleChange(data.story, 'Our Story', '/about/our-story/')
              }
              onFocus={() =>
                handleChange(data.story, 'Our Story', '/about/our-story/')
              }
              onClick={mobileMenuCloseHandler}
              className="story"
            >
              Our Story
            </Link>
            <Link
              to="/about/our-team/"
              onMouseOver={() =>
                handleChange(data.team, 'Our Team', '/about/our-team/')
              }
              onFocus={() =>
                handleChange(data.team, 'Our Team', '/about/our-team/')
              }
              onClick={mobileMenuCloseHandler}
              className="team"
            >
              Our Team
            </Link>
            <Link
              to="/about/reviews/"
              onMouseOver={() =>
                handleChange(data.reviews, 'Reviews', '/about/reviews/')
              }
              onFocus={() =>
                handleChange(data.reviews, 'Reviews', '/about/reviews/')
              }
              onClick={mobileMenuCloseHandler}
              className="reviews"
            >
              Reviews
            </Link>
            <Link
              to="/blog/"
              onMouseOver={() =>
                handleChange(data.blog, 'Blog', '/about/reviews/')
              }
              onFocus={() => handleChange(data.blog, 'Blog', '/about/reviews/')}
              onClick={mobileMenuCloseHandler}
            >
              Blog
            </Link>
          </div>
          <div className="col-right">
            <Link to={link} className="img">
              <Img fixed={img.childImageSharp.fixed} alt={title} />
            </Link>
          </div>
        </div>
      </span>
    </MegaMenuStyles>
  )
}

export default MegaMenuAccessories
