import React, {useContext, useEffect, useState} from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'
import iconSubNav from '../../../images/icons/icon-sub-nav.svg'

import MegaMenuAccessories from './MegaMenuAccessories'
import MegaMenuMattresses from './MegaMenuMattresses'
import MegaMenuBases from './MegaMenuBases'
import MegaMenuAbout from './MegaMenuAbout'

import useMobileHook from '../../../utils/use-mobile-hook'
import AuthContext from '../../Context/AuthContext'

const MainNavigationStyles = styled.nav`
  a.active {
    background: rgba(0, 0, 0, 0.1);
  }
  a,
  .blank-link-no-icon,
  .blank-link {
    color: ${props => props.theme.white};
    text-transform: uppercase;
    font-size: 17px;
    padding: 29px 18px;
    @media (max-width: 1024px) {
      font-size: 15px;
      padding: 29px 12px;
    }
    font-weight: 400;
    letter-spacing: 1px;
    display: inline-block;
    justify-self: center;
    border-bottom: 2px solid transparent;
    transition: all 300ms ease-in-out;
    &:hover {
      @media (min-width: 900px) {
        border-bottom: 2px solid ${props => props.theme.red};
      }
      background: rgba(0, 0, 0, 0.1);
      cursor: pointer;
      .sub-menu,
      .mega-menu {
        @media (min-width: 900px) {
          max-height: 600px;
        }
      }
    }
    .sub-menu,
    .mega-menu {
      @media (min-width: 900px) {
        a[aria-current='page'] {
          border: none;
        }
      }
    }
    &[aria-current='page'] {
      @media (min-width: 900px) {
        border-bottom: 2px solid ${props => props.theme.red};
      }
      @media (max-width: 900px) {
        &:after {
          content: '';
          width: 0;
          height: 0;
          border-left: 10px solid ${props => props.theme.red};
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          position: absolute;
          top: calc(50% - 10px);
          left: 0;
        }
      }
    }
    position: relative;
    .sub-menu {
      background: ${props => props.theme.grey2};
      position: absolute;
      top: 77px;
      left: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-in-out;
      height: auto;
      max-height: 0;
      a {
        display: block;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        text-transform: capitalize;
        font-size: 16px;
        padding: 20px 30px;
        @media (max-width: 1024px) {
          padding: 20px 20px;
        }
        &[aria-current='page']:after {
          content: '';
          width: 0;
          height: 0;
          border-left: 10px solid ${props => props.theme.red};
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          position: absolute;
          top: calc(50% - 10px);
          left: 0;
        }
        @media (min-width: 900px) {
          &:first-child {
            border-top: 2px solid ${props => props.theme.red};
          }
        }
      }
    }
    .mega-menu {
      background: ${props => props.theme.grey2};
      position: fixed;
      top: 119px;
      left: 0;
      width: 100vw;
      overflow: hidden;
      transition: max-height 0.3s ease-in-out;
      height: auto;
      max-height: 0;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    }
  }
  @media (max-width: 900px) {
    position: absolute;
    left: 0;
    top: 79px;
    background: ${props => props.theme.grey2};
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: all 300ms ease-in-out;
    &.open {
      max-height: 100vh;
    }
    a,
    .blank-link-no-icon,
    .blank-link {
      display: block;
      border-bottom: 1px solid ${props => props.theme.grey2};
      padding: 20px;
      transition: all 300ms ease-in-out;
      .sub-menu,
      .mega-menu {
        position: relative;
        top: 21px;
        left: -20px;
        right: -20px;
        max-height: 0;
        transition: all 300ms ease-in-out;
        display: none;
        overflow: hidden;
        background: rgba(0, 0, 0, 0.2);
        width: calc(100% + 40px);
        box-shadow: none;
        a {
          padding: 15px 25px;
          &:hover {
            background: rgba(0, 0, 0, 0.4);
          }
        }
      }
    }
    .blank-link[aria-expanded='true'] {
      .sub-menu,
      .mega-menu {
        display: block;
        max-height: 600px;
        border-top: 2px solid ${props => props.theme.red};
      }
    }
    .blank-link-no-icon,
    .blank-link {
      position: relative;
    }
    .blank-link:before {
      position: absolute;
      right: 20px;
      content: url(${props => props.iconSubNav});
      transition: all 300ms ease-in-out;
    }
    .blank-link[aria-expanded='true']:before {
      transform: rotate(180deg);
    }
  }
`

const MainNavigation = ({mobileMenuVisible, mobileMenuCloseHandler}) => {
  const {loggedInState, performLogout} = useContext(AuthContext)
  const isMobile = useMobileHook({maxWidth: 768})

  const classes = ['main-nav']
  if (mobileMenuVisible) {
    classes.push('open')
  } else classes.push('closed')

  const subMenuClickHandler = e => {
    const expanded = e.target.getAttribute('aria-expanded') === 'true' || false
    e.target.setAttribute('aria-expanded', !expanded)
  }
  const logoutWrapper = () => {
    performLogout('/')
    mobileMenuCloseHandler()
  }

  // On mobile menu add a login and register button
  let MobileExtras = null
  if (isMobile) {
    MobileExtras = loggedInState ? (
      <div className="blank-link-no-icon" onClick={logoutWrapper}>
        Log Out
      </div>
    ) : (
      <>
        <Link to="/login/" onClick={mobileMenuCloseHandler}>
          Login
        </Link>
        <Link to="/register/" onClick={mobileMenuCloseHandler}>
          Register
        </Link>
      </>
    )
  }

  return (
    <MainNavigationStyles iconSubNav={iconSubNav} className={classes.join(' ')}>
      <Link to="/store/" onClick={mobileMenuCloseHandler}>
        Store
      </Link>
      <div
        onClick={subMenuClickHandler}
        className="blank-link"
        aria-expanded="false"
      >
        About
        <MegaMenuAbout mobileMenuCloseHandler={mobileMenuCloseHandler} />
      </div>
      <div
        className="blank-link"
        aria-expanded="false"
        onClick={subMenuClickHandler}
      >
        Mattresses
        <MegaMenuMattresses mobileMenuCloseHandler={mobileMenuCloseHandler} />
      </div>
      {/* <div
        className="blank-link"
        aria-expanded="false"
        onClick={subMenuClickHandler}
      >
        Bases
        <MegaMenuBases mobileMenuCloseHandler={mobileMenuCloseHandler} />
      </div>
      <div
        className="blank-link"
        aria-expanded="false"
        onClick={subMenuClickHandler}
      >
        Accessories
        <MegaMenuAccessories mobileMenuCloseHandler={mobileMenuCloseHandler} />
      </div> */}
      <Link to="/contact-us/" onClick={mobileMenuCloseHandler}>
        Contact Us
      </Link>
      {MobileExtras}
    </MainNavigationStyles>
  )
}

export default MainNavigation
