import {useState, useLayoutEffect} from 'react'

// Hook that return an isMobile check and updates on window resize
const useMobileHook = ({maxWidth = 768}) => {
  const [isMobile, setMobile] = useState(
    typeof window !== 'undefined' ? window.screen.width < maxWidth : false,
  )

  const updateDimensions = () => {
    if (window.screen.width < maxWidth) {
      setMobile(true)
    } else {
      setMobile(false)
    }
  }
  useLayoutEffect(() => {
    window.addEventListener('resize', updateDimensions)
    updateDimensions()
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  return isMobile
}

export default useMobileHook
