import React from 'react'
import styled from 'styled-components'

const IconWrapper = styled.div`
  padding: 27px;
  transition: all 0.2s ease-in-out;
  justify-self: start;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  @media (min-width: 900px) {
    display: none;
  }
`

const Icon = styled.div`
  cursor: pointer;
  margin: 0;
  padding: 0;
  width: 30px;
  height: 25px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #fff;
    border-radius: 10px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }
  span:nth-child(1) {
    top: 0px;
  }
  span:nth-child(2),
  span:nth-child(3) {
    top: 10px;
  }
  span:nth-child(4) {
    top: 20px;
  }
  &.open span:nth-child(1) {
    top: 10px;
    width: 0%;
    left: 50%;
  }
  &.open span:nth-child(2) {
    transform: rotate(45deg);
  }
  &.open span:nth-child(3) {
    transform: rotate(-45deg);
  }
  &.open span:nth-child(4) {
    top: 10px;
    width: 0%;
    left: 50%;
  }
`

function NavIcon({open, mobileMenuToggleHandler}) {
  const classes = ['nav-icon']
  if (open) {
    classes.push('open')
  }
  return (
    <IconWrapper onClick={mobileMenuToggleHandler}>
      <Icon className={classes.join(' ')}>
        <span />
        <span />
        <span />
        <span />
      </Icon>
    </IconWrapper>
  )
}

export default NavIcon
