import React, {useContext, useState, useEffect} from 'react'
import {Link, navigate} from 'gatsby'
import Transition from 'semantic-ui-react/dist/commonjs/modules/Transition'
import Message from 'semantic-ui-react/dist/commonjs/collections/Message'
import styled from 'styled-components'
import MainNavigation from './Navigation/MainNavigation'
import CartContext from '../Context/CartContext'
import {getUser} from '../../services/auth'
import SkinnyHeader from './SkinnyHeader'
import logo from '../../images/kooi-logo-light.svg'
import ShoppingCartIcon from './ShoppingCartIcon'
import NavIcon from './Navigation/navIcon'

const HeaderStyles = styled.nav`
  background: ${props => props.theme.grey2};
  @media (min-width: 900px) {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
`
const Inner = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  padding: 0 20px;
  display: grid;
  grid-template-columns: ${props =>
    props.is_not_cart_page ? '110px 1fr auto' : '1fr'};
  align-items: center;
  justify-items: center;
  @media (max-width: 900px) {
    grid-template-columns: ${props =>
      props.is_not_cart_page ? '30% 40% 30%' : '1fr'};
    position: relative;
    padding: 0;
  }
`
const Logo = styled(Link)`
  max-width: 110px;
  padding: 10px 10px 10px 0;
  justify-self: center;
`

const Header = ({location}) => {
  // Cart Context
  const {cartCount, setCartState, getCartContents} = useContext(CartContext)
  const [noticeTimeout, setNoticeTimeout] = useState(true)
  const [noticeTitle, setNoticeTitle] = useState('')
  const [noticeContent, setNoticeContent] = useState('')

  // Mobile Menu
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false)
  const mobileMenuToggleHandler = () => {
    setMobileMenuVisible(!mobileMenuVisible)
  }
  const mobileMenuCloseHandler = () => {
    setMobileMenuVisible(false)
  }
  const triggerNoticeTimeout = () => {
    setTimeout(() => {
      setNoticeTimeout(false)
    }, 3000)
  }

  useEffect(() => {
    const showNotice = location && location.state && location.state.showNotice
    if (showNotice) {
      if (showNotice === 'register')
        setNoticeTitle('Your account has been registered')
      else setNoticeTitle('You have been logged in')

      const user = getUser()
      setNoticeContent(user.user_email)
    }
    triggerNoticeTimeout()
  }, [])

  let is_not_cart_page = !location.pathname.startsWith('/cart')

  return (
    <>
      {is_not_cart_page && <SkinnyHeader location={location} />}
      <HeaderStyles>
        <Inner is_not_cart_page={is_not_cart_page}>
          {is_not_cart_page && (
            <NavIcon
              open={mobileMenuVisible}
              mobileMenuToggleHandler={mobileMenuToggleHandler}
            />
          )}
          <Logo to="/">
            <img src={logo} alt="Kooi Beds" />
          </Logo>
          {is_not_cart_page && (
            <MainNavigation
              mobileMenuVisible={mobileMenuVisible}
              mobileMenuCloseHandler={mobileMenuCloseHandler}
            />
          )}
          {is_not_cart_page && <ShoppingCartIcon cartCount={cartCount} />}
        </Inner>
      </HeaderStyles>
      {location &&
        location.state &&
        location.state.showNotice &&
        noticeContent && (
          <Transition
            duration={{hide: 500, show: 500}}
            animation="fade down"
            visible={noticeTimeout}
            onStart={triggerNoticeTimeout}
          >
            <Message positive>
              <Message.Header>{noticeTitle}</Message.Header>
              <p>
                Welcome, <b>{noticeContent}</b>
              </p>
            </Message>
          </Transition>
        )}
    </>
  )
}

export default Header
