/* eslint-disable import/prefer-default-export */

import styled from 'styled-components'

export const Inner = styled.div`
  max-width: ${props => props.theme.maxWidth};
  width: calc(100% - 20px);
  margin: 0 auto;
  padding: 0 20px;
`
export const InnerSkinny = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
`
