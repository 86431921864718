import React, {useContext} from 'react'
import Headroom from 'react-headroom'
import 'semantic-ui-css/semantic.min.css'
import styled, {ThemeProvider, createGlobalStyle} from 'styled-components'
import loadable from '@loadable/component'
import Header from '../Header'
import '../styles/wpStyles.css'
import 'typeface-open-sans'
import CartContext from '../Context/CartContext'
import {GoogleTagAllPages} from '../../services/GoogleAnalytics'

// Un-bundle Footer
const Footer = loadable(() => import('../Footer'))

const theme = {
  dark: '#202020',
  white: '#ffffff',
  grey1: '#323e4d',
  grey2: '#162232',
  grey3: '#cad4e0',
  grey4: '#F8F8F8',
  grey5: '#e1e7ed',
  red: '#FF043A',
  redHover: '#d60734',
  firmColor: '#E80019',
  mediumColor: '#FAD200',
  plushColor: '#60C4E8',
  green: '#31D38E',
  maxWidth: '1240px',
  maxWidthSkinny: '800px',
  gutter: '15px',
  vPadding: '60px',
}

const StyledPage = styled.div`
  min-height: 100vh;
  background: ${props =>
    props.background ? props.background : props.theme.white};
  color: ${props => props.theme.dark};
  div.headroom-wrapper {
    height: 119px;
  }
  /* &.cart-page {
    height: 74px;
  } */
`

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: 16px;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    padding: 0;
    margin: 0;
    font-weight:400;
    font-family:  'Open Sans', sans-serif;
    min-height:100%;
  }
  body > div {
    position: relative;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family:  'Open Sans', sans-serif;
    font-weight: normal;
    font-style: normal;
    margin: 0;
    padding: 0;
    color: ${theme.grey1};
  }
  h1 {
    font-size:35px;
  }
  h2 {
    margin: 1em 0 !important;
    text-transform: uppercase;
    text-align:center;
    position: relative;
    &.red:before {
      content: '';
      position: absolute;
      width: 100px;
      height: 2px;
      bottom: -10px;
      left: calc(50% - 50px);
      background-color: ${theme.red};
    }
    &.left:before {
      left: 0 !important;
    }
  }
  h3 {
    margin: 1em 0 0.5em 0;
    font-weight: 600;
    text-transform: uppercase;
  }
  h4 {
    border-bottom:2px solid ${theme.grey3};
    margin: 1em 0 0.5em 0;
  }
  p {
    line-height: 25px;
    color: ${theme.grey1};
  }
  .content a {
    text-decoration: underline;
    color: ${theme.grey1};
    &:hover {
      color: ${theme.red};
    }
  }
  .content li {
    color: ${theme.grey1};
    line-height:25px;
  }
  img {
    max-width: 100%;
    height:auto;
  }
  .ui.button.button-primary {
    background-color:${theme.red};
    color:${theme.white};
    border-radius:50px;
    text-transform: uppercase;
    transition: all 300ms ease-in-out;
    font-size:18px;
    padding:16px 45px;
    &:hover {
      background-color:${theme.redHover};
    }
  }
  .ui.button.button-secondary {
    background-color:${theme.grey2};
    color:${theme.white};
    border-radius:50px;
    text-transform: uppercase;
    transition: all 300ms ease-in-out;
    font-size:18px;
    padding:16px 45px;
    &:hover {
      background-color:${theme.grey1};
    }
  }
  .ui.button.button-tertiary {
    background-color:rgba(0,0,0,0.2);
    color:rgba(0,0,0,0.6);
    border-radius:50px;
    text-transform: uppercase;
    transition: all 300ms ease-in-out;
    font-size:18px;
    padding:16px 45px;
    &:hover {
      background-color:rgba(0,0,0,0.3);
    }
  }
`

const Layout = ({location, children, flush, background}) => {
  let page_class = ''
  if (location.pathname.includes('/cart/')) {
    page_class = 'cart-page'
  }

  return (
    <ThemeProvider theme={theme}>
      <GoogleTagAllPages />
      <StyledPage background={background} className={page_class}>
        <GlobalStyle />
        <Headroom upTolerance={10} downTolerance={10} style={{zIndex: '20'}}>
          <Header location={location} />
        </Headroom>
        {children}
        <Footer flush={flush} location={location} />
      </StyledPage>
    </ThemeProvider>
  )
}

export default Layout
