/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'
import icon from '../../images/icons/shopping-cart.svg'

const ShoppingCartStyled = styled(Link)`
  justify-self: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.05);
  padding: 27px 10px;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  transition: all 200ms ease-in-out;
  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
  img {
    justify-self: center;
    height: 100%;
    max-height: 30px;
    width: 30px;
  }
  @media (max-width: 900px) {
    justify-self: center;
    width: auto;
  }
  @media (max-width: 480px) {
    justify-self: center;
    width: auto;
    max-height: 80px;
    box-sizing: border-box;
    img {
      justify-self: center;
      height: auto;
    }
  }
`
const Counter = styled.div`
  background: ${props =>
    Number(props.cartCount) === 0
      ? props => props.theme.grey1
      : props => props.theme.red};
  color: ${props => props.theme.white};
  border-radius: 40px;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  padding: 5.5px;
  width: 30px;
  height: 30px;
  justify-self: center;
`

const ShoppingCartIcon = ({cartCount}) => {
  const showCartCount = () => {
    if (!cartCount) {
      return `0`
    }
    if (cartCount > 9) {
      return `9+`
    }
    return `${cartCount}`
  }
  return (
    <ShoppingCartStyled
      to="/cart/"
      title={
        Number(cartCount) === 0
          ? `Your Shopping Cart is Empty`
          : `You have ${cartCount} items in your cart`
      }
    >
      <img src={icon} alt="Your Shopping Cart" />
      <Counter cartCount={cartCount}>{showCartCount()}</Counter>
    </ShoppingCartStyled>
  )
}

export default ShoppingCartIcon
