import React, {useEffect, useContext, useState} from 'react'
import styled from 'styled-components'
import {Link, graphql, StaticQuery} from 'gatsby'
import {isLoggedIn} from '../../services/auth'
import useMobileHook from '../../utils/use-mobile-hook'
import AuthContext from '../../components/Context/AuthContext'
import IconUser from '../../images/icons/icon-user.svg'
import IconLock from '../../images/icons/icon-lock.svg'
import IconStripe from '../../images/powered-by-stripe.svg'
import LogoMasterCard from '../../images/icons/logo-mastercard'
import LogoVisa from '../../images/icons/logo-visa'

const SkinnyHeaderStyled = styled.div`
  background: ${props => props.theme.grey1};
  a {
    color: ${props => props.theme.white};
    opacity: 0.8;
    padding: 10px 0;
    display: inline-block;
    transition: all 200ms ease-in-out;
    &:hover {
      transform: translateY(-3px);
      opacity: 1;
    }
  }
`
const Inner = styled.div`
  grid-template-columns: 28% 50% 22%;
  display: grid;
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  padding: 0 20px;
  @media (max-width: 768px) {
    padding: 0;
    display: flex;
  }
`
const SupportInfo = styled.div`
  padding-left: 15px;
  a {
    padding-right: 15px;
  }
  @media (max-width: 768px) {
    .mail {
      display: none;
    }
  }
  @media (max-width: 550px) {
    display: none;
  }
`
const PaymentIcons = styled.div`
  display: flex;
  justify-self: center;
  align-self: center;
  height: 100%;
  display: flex;
  img {
    height: 60%;
    margin: auto;
    @media (max-width: 480px) {
      margin: auto 10px 0 10px;
    }
  }
  svg {
    height: 100%;
    width: 25px;
    margin: 1px 10px 0 10px;
    &:hover {
      opacity: 0.8;
    }
  }
  @media (max-width: 768px) {
    flex: 1 1 auto;
    justify-content: flex-end;
    padding-right: 30px;
  }
`
const Menu = styled.div`
  // justify-self: end;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, auto);
    min-width: 260px;
  }
  a {
    padding-left: 15px;
    padding-right: 15px;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 8px;
    align-items: center;
    min-width: 130px;
    @media (max-width: 480px) {
      min-width: 0px;
    }
    &.logout {
      justify-content: center;
      display: flex;
      span,
      img {
        padding: 0 4px;
      }
    }
  }
  @media (max-width: 480px) {
    .link-left {
      grid-template-columns: 1fr auto;
      img {
        justify-self: flex-end;
      }
    }
  }
`
const DesktopLink = styled(Link)`
  &.desktop-link {
    @media (max-width: 768px) {
      display: none;
    }
  }
`

const GENERIC_CONTACT_INFO = graphql`
  query GenericContactDetailsSkinnyHeader {
    wordpressPage(wordpress_id: {eq: 935}) {
      acf {
        phone
        email
      }
    }
  }
`

const SkinnyHeader = ({location}) => {
  const {loggedInState, performLogout} = useContext(AuthContext)
  const isMobile = useMobileHook({maxWidth: 768})
  const [loginButtonTextState, setLoginButtonTextState] = useState('Login')

  useEffect(() => {
    if (loggedInState) {
      setLoginButtonTextState('My Account')
    } else {
      setLoginButtonTextState('Login')
    }
  }, [loggedInState])

  const logoutWrapper = () => performLogout('/')

  // Change the login/register icons depending on mobile and logged in status
  let MenuItem = (
    <>
      <DesktopLink className="desktop-link link-left" to="/register/">
        <img src={IconUser} alt="" />
        Register
      </DesktopLink>
      <Link
        to="/login/"
        state={{
          fromUrl: location && location.pathname ? location.pathname : '/',
        }}
      >
        <img src={IconLock} alt="" />
        {loginButtonTextState}
      </Link>
    </>
  )
  if (loggedInState === true) {
    MenuItem = (
      <Link to="/profile/" className="link-left">
        <img src={IconUser} alt="" />
        {loginButtonTextState}
      </Link>
    )
  }

  return (
    <SkinnyHeaderStyled>
      <Inner>
        <SupportInfo>
          <StaticQuery
            query={GENERIC_CONTACT_INFO}
            render={({wordpressPage}) => (
              <>
                <a href={`tel:${wordpressPage.acf.phone}`} className="tel">
                  {wordpressPage.acf.phone}
                </a>
                <a href={`mailto:${wordpressPage.acf.email}`} className="mail">
                  {wordpressPage.acf.email}
                </a>
              </>
            )}
          />
        </SupportInfo>
        <PaymentIcons>
          <img src={IconStripe} alt="" />
          <LogoMasterCard fill="white" />
          <LogoVisa fill="white" />
        </PaymentIcons>
        <Menu>{MenuItem}</Menu>
      </Inner>
    </SkinnyHeaderStyled>
  )
}

export default SkinnyHeader
