import React from 'react'

const LogoVisa = ({fill}) => (
  <svg viewBox="0 0 16 13" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.8515 5.4414c.0912.4193.1732.8294.2461 1.2305h-.9023l.4375-1.1758.0547-.1367.082-.2735.082.3555zM15.75 1.3125v9.625c0 .3646-.1276.6745-.3828.9297-.2553.2552-.5652.3828-.9297.3828H1.3125c-.3646 0-.6745-.1276-.9297-.3828S0 11.302 0 10.9375v-9.625C0 .9479.1276.638.3828.3828S.948 0 1.3125 0h13.125c.3645 0 .6744.1276.9297.3828.2552.2552.3828.5651.3828.9297zM4.1836 8.1758l1.7226-4.2383H4.7305L3.664 6.8359l-.4922-2.543c-.0547-.2187-.2279-.3372-.5196-.3554h-1.75l-.0273.082c.4193.1094.802.2643 1.1484.4649l.9844 3.6914h1.1758zm2.5703 0l.6836-4.2383H6.3437l-.6836 4.2383H6.754zm3.8281-1.3672c0-.474-.3099-.866-.9297-1.1758-.2187-.1094-.3782-.2005-.4785-.2734-.1003-.073-.1458-.155-.1367-.2461.0091-.0912.0638-.1732.164-.2461.1003-.073.2507-.1094.4512-.1094.2917-.0182.5651.0365.8203.164l.1094.0274.164-.9023c-.3098-.1276-.638-.1914-.9843-.1914-.5469 0-.9935.1321-1.3398.3964-.3464.2644-.5241.6107-.5332 1.0391-.0092.4284.3144.8066.9707 1.1348.2005.0911.3463.1777.4375.2597.0911.082.1367.1687.1367.2598 0 .1276-.0684.2279-.2051.3008-.1367.0729-.3008.1094-.4922.1094-.1914 0-.36-.0137-.5059-.041-.1458-.0274-.3098-.0867-.4921-.1778l-.1368-.082-.164.957c.3099.1458.72.2279 1.2304.246.5652 0 1.0254-.132 1.3809-.3964.3555-.2643.5332-.6152.5332-1.0527zm3.8555 1.3672l-.875-4.2383h-.8477c-.2917 0-.483.1185-.5742.3555L10.5 8.1758h1.1484l.2188-.629h1.4218l.1367.629h1.0118z"
      fill={fill}
    />
  </svg>
)

export default LogoVisa
